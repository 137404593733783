<template>
  <div class=" Cat-Slide-item">
    <div class="product-grid">
      <div class="product-image">
     
        <router-link
          :to="{
            name: 'ProdByCat',
            params: {  ProdByCat: Category.id },
          }"
          class="image"
          style="min-width: 150"
        >
        
          <vue-load-image>
            
            <img
              slot="image"
              class="pic-1 img-fluid mx-auto pt-1"
              :src="Category.image.src?Category.image.src:'@/assets/placeholder.png'"
              :alt="Category.name"
              width="210"
              height="210"
           
            />
            <img
              slot="preloader"
              class="pic-1 img-fluid mx-auto"
              src="@/assets/loader.gif"
              width="210"
              height="210"
              :alt="Category.name"
            />
            <img
              slot="error"
              class="pic-1 img-fluid mx-auto"
              src="@/assets/placeholder.png"
              width="210"
              height="210"
              :alt="Category.name"
            />
          </vue-load-image>
        </router-link>
  
      </div>
      <div class="product-data">
        <h3 class="title">
          <router-link
           :to="{
            name: 'ProdByCat',
            params: {  ProdByCat: Category.id },
          }"
            >{{ Category.name }}</router-link
          >
        </h3>
       
      </div>
       
    </div>
  </div>

  
</template>

<script>
import VueLoadImage from "vue-load-image";

export default {
  
  name: "CatSideItem",
    components: {
     "vue-load-image": VueLoadImage,
  },

  props: ["Category"],
 
  
};
</script>

<style>
.Cat-Slide-item {
 
  display: inline-block;
  /* cursor: pointer; */
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  transition: all 0.3s ease; 
  margin: 0px 10px;
 

}
.Cat-Slide-item:hover {
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    margin: 0px 20px;
}

 
 

 
.card {
  border: none !important;
}
 
 
.product-grid {
  text-align: center;
  border-radius: 15px;
  /* border: 1px solid #e7e7e7; */
  overflow: hidden;
  /* transition: all 0.4s ease-out; */
  background: none;
  background-color: none;
  /* width: 70%; */
  height: auto;
}

 
 
.pic-1:hover + .product-description {
  display: block;
}
.product-grid .product-image {
  position: relative;
}
.product-grid .product-image a.image {
  display: block;
}
.product-grid .product-image img {
  height: auto;
}
.product-grid.descrition p {
  max-width: 20ch;
  white-space: break-spaces;
}
 
.product-grid .product-links {
  width: 145px;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 65%;
  left: 50%;
  transition: all 0.4s ease 0s;
}
.product-grid:hover .product-links {
  opacity: 1;
  top: 50%;
}
.product-grid .product-links li {
  display: inline-block;
  margin: 0 2px;
}
.product-grid .product-links li a {
  color: #2c2c2c;
  background: #fff;
  font-size: 16px;
  line-height: 42px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.4s ease-out 0s;
}
.product-grid .product-links li a:hover {
  color: #fff;
  background: #fe6a00;
  box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.2);
}
.product-grid .product-links li a:before,
.product-grid .product-links li a:after {
  content: attr(data-tip);
  color: #fff;
  background-color: #555;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 10px;
  white-space: nowrap;
  display: none;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -40px;
  transition: all 0.3s ease 0s;
}
.product-grid .product-links li a:after {
  content: "";
  height: 10px;
  width: 10px;
  padding: 0;
  transform: translateX(-50%) rotate(45deg);
  top: -18px;
  z-index: -1;
}
.product-grid .product-links li a:hover:before,
.product-grid .product-links li a:hover:after {
  display: block;
}
.product-grid .product-content {
  padding: 0px 12px 2px;
  position: relative;
}
.product-grid .rating {
  padding: 0;
  margin: 15px 0 0px;
  list-style: none;
}
.product-grid .rating li {
  color: #fe6a00;
  font-size: 14px;
}
.product-grid .rating .far {
  color: #808080;
}
.product-grid .title {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 1px 1px 15px;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-grid .title a {
  color: #eb5b2c;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  max-width: 75ch;
  text-decoration: none;
}

.product-grid .product-data .title a:hover {
  color: #fe6a00;
}
.product-grid .price {
  color: #fe6a00;
  font-size: 14px;
  /* font-weight: 600; */
  display: block;
  transition: all 0.4s ease-in-out;
}

.price span {
  color: #fe6a00;
}

.product-content .add-to-favorite {
  color: #fe6a00;
  display: block;
  background-color: white;
  border: 1px #fe6a00 solid;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 40px;
  width: 128px;
  border-radius: 50px;
  padding: 2px;
  margin: 14px auto;
  transition: all 0.4s ease-out;
}

.product-data {
  background-color: white;
  transition: all 0.4s ease-out;
  padding: 6px 0;
  padding-bottom: 0px !important;
  z-index: 666;
  position: relative;
}

.product-grid.descrition {
  display: none;
  position: absolute;
  background-color: white;
  text-align: center;
  white-space: break-spaces;
  transition: all 0.4s ease-out;
  width: 100% !important;
}

/*  Dsecription Animation When Hover On Img  */

.product-grid .product-image:hover + .product-grid.descrition {
  display: block;
  transition: all 0.4s ease-out;
}

.product-grid .product-image img {
  vertical-align: top;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.product-grid .product-image:hover > a > img {
  opacity: 0.2;
}

.product-grid:hover + .product-image .product-description {
  display: block !important;
}
.rating li {
  display: inline-block;
}
@media screen and (max-width: 1200px) {
  .product-grid {
    margin: 0 0 2px;
  }
}
</style>